<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadUsers(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showUserDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="users.data" class="mt-2">
                <el-table-column prop="phone" label="注册登录手机号" sortable />
                <el-table-column prop="companyName" label="水发公司" sortable />
                <el-table-column prop="name" label="企业名称" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.id" target="_blank">
                            {{scope.row.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="idCard" label="统一社会信用代码" sortable />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showUserDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="disableUser(scope.row.id)">
                            禁用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="users.totalRecords"
                :current-page="usersPagination.currentPage" :page-size="usersPagination.pageSize" class="mt-3"
                @current-change="usersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="userDialogVisible" title="用户" :close-on-click-modal="false" width="640px">
            <el-form>
                <el-form-item label="水发公司" class="form-item-required">
                    <el-select v-model="user.companyId" placeholder="水发公司">
                        <el-option v-for="company in companies" :key="company.id" :value="company.id"
                            :label="company.fullName" />
                    </el-select>
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="注册登录手机号" class="form-item-required">
                            <el-input v-model="user.phone" placeholder="注册登录手机号" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="企业名称" class="form-item-required">
                            <el-input v-model="user.name" placeholder="企业名称" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="统一社会信用代码" class="form-item-required">
                            <el-input v-model="user.idCard" placeholder="统一社会信用代码" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="法人" class="form-item-required">
                            <el-input v-model="user.legalPersonName" placeholder="法人" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="营业执照" class="form-item-required">
                    <file-uploader v-model="user.businessLicenseUrl" accept=".jpg,.png">
                        <template #default="scope">
                            <el-image :src="'/'+scope.file" fit="contain" class="border rounded w-100"
                                style="height: 240px;" />
                        </template>
                    </file-uploader>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveUser">确定</el-button>
                <el-button @click="userDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                companies: [],
                key: null,
                users: {},
                usersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                user: {},
                userDialogVisible: false,
            };
        },
        methods: {
            async loadCompanies() {
                let response = await this.$axios.get('/api/Organization/GetCompanies');
                this.companies = response.data;
            },
            async loadUsers(resetPage = false) {
                if (resetPage) {
                    this.usersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/RegisteredUser/GetEnterpriseUsers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        authenticationStatus: 3,
                        key: this.key,
                        pageIndex: this.usersPagination.currentPage - 1,
                        pageSize: this.usersPagination.pageSize,
                    }
                });
                this.users = response.data;
            },
            usersPaginationCurrentChange(page) {
                this.usersPagination.currentPage = page;
                this.loadUsers();
            },
            showUserDialog(user) {
                if (user) {
                    this.user = JSON.parse(JSON.stringify(user));
                }
                else {
                    this.user = {};
                }
                this.userDialogVisible = true;
            },
            async saveUser() {
                if (this.user.id == undefined) {
                    await this.$axios.post('/api/RegisteredUser/AddAuthenticatedEnterpriseUser', {
                        companyId: this.user.companyId,
                        phone: this.user.phone,
                        name: this.user.name,
                        idCard: this.user.idCard,
                        legalPersonName: this.user.legalPersonName,
                        businessLicenseUrl: this.user.businessLicenseUrl,
                    });
                    this.userDialogVisible = false;
                    this.loadUsers();
                }
                else {
                    await this.$axios.post('/api/RegisteredUser/UpdateAuthenticatedEnterpriseUser', {
                        id: this.user.id,
                        companyId: this.user.companyId,
                        phone: this.user.phone,
                        name: this.user.name,
                        idCard: this.user.idCard,
                        legalPersonName: this.user.legalPersonName,
                        businessLicenseUrl: this.user.businessLicenseUrl,
                    });
                    this.userDialogVisible = false;
                    this.loadUsers();
                }
            },
            async disableUser(id) {
                if (!confirm('确定要禁用吗？')) {
                    return;
                }

                await this.$axios.post('/api/RegisteredUser/DisableRegisteredUser', { id: id });
                this.loadUsers();
            },
        },
        created() {
            this.loadCompanies();
            this.loadUsers();
        },
    };
</script>